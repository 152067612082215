// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Content
\* ------------------------------------------------------------------------ */

.content-block {
    & {
        @include layer("content");
    }
}

.content_inner {
    & {
        background: $background;
        margin: 0 auto;
        max-width: remify($site-width);
        padding: remify(($content-padding * 0.5) ($content-padding * 0.3333333333333333));
    }
}

/* fullbleed variant */

.content-block.-fullbleed {
    & {
        background: $background;
        padding: remify(($content-padding * 0.5) ($content-padding * 0.3333333333333333));
    }

    > .content_inner {
        background: none;
        padding: 0;
    }

    > .content_inner.-fullbleed {
        max-width: none;
    }
}
