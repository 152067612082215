// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Callouts
\* ------------------------------------------------------------------------ */

.callouts-block {
    & {
        @include layer("callouts");
    }
}

.callouts_inner {
    & {
        background: linear-gradient(to right, $background_alt 5%, lighten($background_alt, 14.5098) 50%, $background_alt 95%);
        margin: 0 auto;
        max-width: remify($site-width, 16);
        padding: remify(($content-padding * 0.6666666666666667) ($content-padding * 0.3333333333333333));
    }
}

.callouts_title {
    & {
        margin-bottom: remify(27, 20);
    }
}

/* fullbleed variant */

.callouts-block.-fullbleed {
    & {
        background: linear-gradient(to right, $background_alt 5%, lighten($background_alt, 14.5098) 50%, $background_alt 95%);
        padding: remify(($content-padding * 0.6666666666666667) ($content-padding * 0.3333333333333333));
    }

    > .callouts_inner {
        background: none;
        padding: 0;
    }

    > .callouts_inner.-fullbleed {
        max-width: none;
    }
}
