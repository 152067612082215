// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Swiper (http://idangero.us/swiper/)
\* ------------------------------------------------------------------------ */

/* stylelint-disable selector-class-pattern */

// Vendor

@import "../../vendor/_swiper";

// Custom

.swiper-slide {
    & {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        height: auto;
        margin: 0;
        overflow: hidden;
    }

    &::before {
        background: transparentize($primary, 0.5);
        content: "";
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 1;
    }
}

.swiper-picture {
    & {
        flex: none;
        position: relative;
        z-index: 0;
    }
}

.swiper-image {
    & {
        display: block;
        filter: grayscale(1);
        width: 100%;
    }

    .swiper-container.-fixedheight & {
        left: 50%;
        min-width: 100%;
        position: relative;
        transform: translateX(-50%);
        width: auto;
    }
}

.swiper-video_spacer {
    & {
        height: remify(360, 16);
        width: 100%;
    }
}

.swiper-video_container {
    & {
        height: remify(360, 16);
        left: 50%;
        position: absolute;
        top: 0;
        transform: translateX(-50%);
        width: remify(640, 16);
    }
}

.swiper-video {
    & {
        filter: grayscale(100);
        height: 100% !important;
        left: 0;
        position: absolute;
        top: 0;
        width: 100% !important;
    }
}

.swiper-caption {
    & {
        left: 0;
        margin: 0 auto;
        max-width: 100%;
        padding: remify(($content-padding * 0.4166666666666667) ($content-padding * 0.3333333333333333));
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: remify($site-width * 0.5763888888888889, 16);
        z-index: 2;
    }
}

.swiper-title {
    & {
        color: $light;
        font-range: $xs-break $xl-break;
        font-size: responsive remify(30, 16) remify(50, 16);
        font-weight: 900;
        line-height: remify(30, 30);
        margin-bottom: remify(18, 30);
        text-align: center;
        text-shadow: remify(0 2 4) transparentize($dark, 0.5);
    }
}

.swiper-divider {
    & {
        background-color: $light;
        font-range: $xs-break $xl-break;
        font-size: responsive remify(15, 16) remify(22, 16);
        height: remify(3);
        margin-bottom: remify(15, 15);
    }
}

.swiper-user-content {
    /* stylelint-disable selector-max-type */

    & {
        text-align: center;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: $light;
        font-range: $xs-break $xl-break;
        font-size: responsive remify(30, 16) remify(50, 16);
        font-weight: 900;
        line-height: remify(30, 30);
        margin-bottom: remify(18, 30);
        text-align: center;
        text-shadow: remify(0 2 4) transparentize($dark, 0.5);
    }

    p,
    ol,
    ul,
    table {
        color: $light;
        font-range: $xs-break $xl-break;
        font-size: responsive remify(20, 16) remify(25, 16);
        font-weight: 500;
        line-height: remify(25.6, 20);
        text-align: center;
        text-shadow: remify(0 2 4) transparentize($dark, 0.5);
    }

    hr {
        background-color: $light;
        font-range: $xs-break $xl-break;
        font-size: responsive remify(15, 16) remify(22, 16);
        height: remify(3);
        margin-bottom: remify(15, 15);
    }

    > :last-child {
        margin-bottom: 0 !important;
    }

    /* stylelint-enable selector-max-type */
}

.swiper-button {
    & {
        background: none;
        border: 0;
        color: $primary;
        display: block;
        height: 100%;
        margin: 0;
        opacity: 0;
        padding: 0;
        top: 0;
        transition: opacity 0.15s;
        width: remify(55, 16);
    }

    &.-prev {
        background: none;
        left: 0;
    }

    .swiper-container-rtl &.-prev {
        background: none;
        right: 0;
    }

    &.-next {
        background: none;
        right: 0;
    }

    .swiper-container-rtl &.-next {
        background: none;
        left: 0;
    }

    .swiper-container:hover & {
        opacity: 0.5;
    }

    .swiper-container:hover &:hover,
    .swiper-container:hover &:focus {
        opacity: 1;
    }

    .swiper-button-icon,
    .swiper-button-icon {
        font-size: remify(48, 16);
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
    }
}

.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
    & {
        bottom: auto;
        top: 0;
    }
}

.swiper-pagination-bullet {
    & {
        background: transparentize($dark, 0.5);
        height: remify(10, 16);
        opacity: 1;
        transition: background 0.15s;
        width: remify(10, 16);
    }
}

.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
    & {
        margin: remify(20 5, 16);
    }
}

.swiper-pagination-bullet-active {
    & {
        background: $primary;
    }
}
