// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Button
\* ------------------------------------------------------------------------ */

.button {
    & {
        background: $accent;
        border: 0;
        border-radius: remify(100, 20);
        color: $primary_alt;
        cursor: pointer;
        display: inline-block;
        font-display: swap;
        font-family: $heading-font;
        font-range: $xs-break $xl-break;
        font-size: responsive remify(20, 16) remify(24, 16);
        font-weight: 500;
        line-height: remify(24, 20);
        margin: remify(0 0 12, 20);
        outline: 0;
        padding: remify(13 40 14, 20);
        text-decoration: none;
        transition: background-color 0.15s;
    }

    &:focus,
    &:hover {
        background-color: lighten($accent, 10);
    }
}
