// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Text
\* ------------------------------------------------------------------------ */

.text {
    & {
        color: $foreground;
        font-display: swap;
        font-family: $body-font;
        font-range: $xs-break $xl-break;
        font-size: responsive remify(14, 16) remify(18, 16);
        font-weight: 500;
        line-height: remify(20, 14);
        margin: remify(0 0 18, 14);
    }

    .button {
        font-range: $xs-break $xl-break;
        font-size: responsive remify(20, 14) remify(24, 18);
    }
}

/* label variant */

.text.-label {
    & {
        cursor: pointer;
    }
}

/* list variant */

.text.-list {
    & {
        list-style: disc;
        padding: remify(0 0 0 20, 14);
    }

    .text_list-item {
        display: list-item;
    }
}
