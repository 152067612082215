// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Banner
\* ------------------------------------------------------------------------ */

.banner-block {
    & {
        @include layer("banner");
    }
}

.banner_inner {
    & {
        background: url("../media/background-banner.jpg") center top / cover no-repeat $primary;
        margin: 0 auto;
        max-width: remify($site-width * 0.5763888888888889, 16);
        padding: remify(($content-padding * 0.8333333333333333) ($content-padding * 0.3333333333333333));
    }
}

.banner_user-content {
    /* stylelint-disable selector-max-type */

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: $light;
        text-align: center;
    }

    p,
    ol,
    ul,
    table {
        color: $light;
        font-range: $xs-break $xl-break;
        font-size: responsive remify(20, 16) remify(25, 16);
        font-weight: 500;
        line-height: remify(25.6, 20);
        text-align: center;
        text-shadow: remify(0 2 4) transparentize($dark, 0.5);
    }

    > :last-child {
        margin-bottom: 0 !important;
    }

    /* stylelint-enable selector-max-type */
}

/* fullbleed variant */

.banner-block.-fullbleed {
    & {
        background-color: $primary;
        min-height: remify(160, 16);
        overflow: hidden;
        padding: remify(($content-padding * 0.8333333333333333) ($content-padding * 0.3333333333333333));
        position: relative;
    }

    > .banner_inner {
        background: none;
        padding: 0;
    }

    > .banner_inner.-fullbleed {
        max-width: none;
    }

    .banner_image_container {
        display: flex;
        height: 100%;
        left: 0;
        margin: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: -1;
    }

    .banner_image {
        filter: grayscale(100%);
        height: 100%;
        object-fit: cover;
        object-position: center center;
        width: 100%;
    }
}

/* overlay variant */

.banner-block.-overlay {
    &::after {
        background: $primary;
        content: "";
        display: block;
        height: 100%;
        left: 0;
        opacity: 0.6;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: -1;
    }
}
