// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Title
\* ------------------------------------------------------------------------ */

.title {
    & {
        color: $primary;
        font-display: swap;
        font-family: $heading-font;
        font-range: $xs-break $xl-break;
        font-size: responsive remify(20, 16) remify(40, 16);
        font-weight: 600;
        line-height: remify(23.5, 20);
        margin: remify(0 0 13.5, 20);
    }
}

/* divider variant */

.title.-divider {
    & {
        align-items: center;
        display: flex;
    }

    &::before,
    &::after {
        background: darken($background_alt, 10);
        content: "\0020";
        display: block;
        flex: 1 1 0%;
        height: remify(1);
    }

    &::before {
        margin-right: remify(16, 16);
    }

    &::after {
        margin-left: remify(16, 16);
    }
}
