// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Header
\* ------------------------------------------------------------------------ */

.header-block {
    & {
        @include layer("header");
    }
}

.header_inner {
    & {
        background: $background;
        box-shadow: remify(0 2 4 0) transparentize($dark, 0.75);
        margin: 0 auto;
        max-width: remify($site-width);
        padding: remify(($content-padding * 0.4166666666666667) ($content-padding * 0.3333333333333333));
    }
}

.header_logo {
    & {
        max-width: remify(180, 16);
    }
}

/* fullbleed variant */

.header-block.-fullbleed {
    & {
        background: $background;
        box-shadow: remify(0 2 4 0) transparentize($dark, 0.75);
        padding: remify(($content-padding * 0.4166666666666667) ($content-padding * 0.3333333333333333));
    }

    > .header_inner {
        background: none;
        box-shadow: none;
        padding: 0;
    }

    > .header_inner.-fullbleed {
        max-width: none;
    }
}
