// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Callout
\* ------------------------------------------------------------------------ */

.callout {
    & {
        align-items: center;
        background-color: $primary_alt;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        color: $light;
        display: flex;
        font-family: $heading-font;
        font-range: $xs-break $xl-break;
        font-size: responsive remify(30, 16) remify(44, 16);
        font-weight: 900;
        justify-content: center;
        line-height: remify(37, 30);
        margin: remify(1);
        min-height: remify(150);
        position: relative;
        text-align: center;
        text-decoration: none;
        width: 100%;
    }

    &::before {
        background: transparentize($dark, 0.5);
        content: "";
        display: block;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 0;
    }
}

.callout_title {
    & {
        margin: remify(16, 16);
        position: relative;
        z-index: 1;
    }
}
