// Styles written by Jordan Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * WP Video
\* ------------------------------------------------------------------------ */

.wp-video {
    & {
        margin: remify(0 0 20.57142857142857, 16);
    }
}
