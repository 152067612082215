// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Footer
\* ------------------------------------------------------------------------ */

.footer-block {
    & {
        @include layer("footer");
    }
}

.footer_inner {
    & {
        background: $page_background_alt;
        margin: 0 auto;
        max-width: remify($site-width * 0.85);
        padding: remify(($content-padding * 0.8333333333333333) ($content-padding * 0.3333333333333333));
    }
}

.footer_link {
    & {
        color: lighten(desaturate(adjust-hue($page_background_alt, 0.1238), 14.0317), 40.3922);
    }

    &:focus,
    &:hover {
        color: lighten(desaturate(adjust-hue($page_background_alt, 0.1238), 14.0317), 40.3922);
        text-decoration: underline;
    }
}

.footer_text {
    & {
        color: lighten(desaturate(adjust-hue($page_background_alt, 0.1238), 14.0317), 40.3922);
        font-size: remify(16, 16);
        line-height: remify(19, 16);
        margin-bottom: remify(37, 16);
    }

    &.-alt {
        color: $light;
        margin-bottom: remify(10, 16);
    }
}

/* fullbleed variant */

.footer-block.-fullbleed {
    & {
        background: $page_background_alt;
        padding: remify(($content-padding * 0.8333333333333333) ($content-padding * 0.3333333333333333));
    }

    > .footer_inner {
        background: none;
        padding: 0;
    }

    > .footer_inner.-fullbleed {
        max-width: none;
    }
}

/* overlay variant */

.footer-block.-overlay {
    &::after {
        background: $primary;
        content: "";
        display: block;
        height: 100%;
        left: 0;
        opacity: 0.8;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: -1;
    }

    > .footer_inner {
        background: none;
        padding: 0;
    }

    > .footer_inner.-fullbleed {
        max-width: none;
    }

    .footer_image_container {
        display: flex;
        height: 100%;
        left: 0;
        margin: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: -1;
    }

    .footer_image {
        filter: grayscale(100%);
        height: 100%;
        object-fit: cover;
        object-position: center center;
        width: 100%;
    }

    .footer_row {
        margin-bottom: remify(32);
    }
}

/* alt variant */

.footer-block.-alt {
    > .footer_inner {
        background: $page_background;
        padding: 0;
        padding: remify(($content-padding * 0.5) ($content-padding * 0.3333333333333333));
    }
}

/* alt fullbleed variant */

.footer-block.-alt.-fullbleed {
    & {
        background: $page_background;
        padding: remify(($content-padding * 0.5) ($content-padding * 0.3333333333333333));
    }

    > .footer_inner {
        background: none;
        padding: 0;
    }
}
